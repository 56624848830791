.athlete {
  &__list {
    padding: 0;
    list-style-type: none;
  }

  &__single {
    padding: 25px $container-padding;
    display: flex;
    align-items: center;
    color: $color-white;

    @include mobile {
      padding: 10px $container-padding-mobile;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $color-gray;
    }

    &.limit-reached {
      border-bottom-color: $color-yellow;
    }
    
    &:hover {
      background-color: $color-gray;

      .review__button {
        opacity: 1;
      }

      .athlete__done .btn {
        display: block;
      }
    }
    
    .review__button {
      opacity: 0;
      @media (hover: none) {
        opacity: 1;
      }
      margin-left: auto;
    }
  }

  &__ordinal {
    margin-right: 20px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__avatar {
    width: 50px;
    height: 50px;
    flex: 0 0 50px;
    background-color: skyblue;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    
    @include mobile {
      width: 35px;
      height: 35px;
      flex: 0 0 35px;

      &::before {
        width: 20px;
        height: 10px;
      }
    }
  }

  &__single &__avatar {
    &::before {
      content: '';
      display: block;
      width: 30px;
      height: 20px;
      top: -3px;
      position: absolute;
      left: -3px;
      background: rgb(71, 71, 247);
    }
  }

  &__name {
    margin: 0;
    color: $color-white;
  }

  &__status {
    margin: 0;
    font-size: 13px;
    font-weight: 400;
    color: #838383;
  }

  &__done {
    margin-left: auto;
    display: flex;
    align-items: center;

    .btn {
      display: none;
      
      @media (hover: none) {
        display: block;
      }
      margin-left: 10px;
    }

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: $color-green;
      border-radius: 50%;
    }
  }

  &__disqualificated {
    margin-left: auto;

    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      background-color: $color-red;
      border-radius: 50%;
    }
  }
}