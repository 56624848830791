$color-black:#1a1a1a;
$color-gray:#292929;
$color-white:#fff;
$color-green:#38b62e;
$color-yellow:#ff9c00;
$color-red:#fc2626;

$container-padding: 40px;
$container-padding-mobile: 15px;

@mixin mobile() {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin mobileUp() {
  @media (min-width: 768px) {
    @content;
  }
}

%reset-Button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  cursor: pointer;
  outline: none;

  background: transparent;

  /* inherit font & color from ancestor */
  color: inherit;
  font: inherit;

  /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
  line-height: normal;

  /* Corrects font smoothing for webkit */
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;

  /* Corrects inability to style clickable `input` types in iOS */
  -webkit-appearance: none;
}

*, *::before, *::after {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $color-gray;
  // overflow-y: scroll;
}

%container {
  max-width: 1180px;
  padding: 0 $container-padding;
  width: 100%;
  margin: 0 auto;

  @include mobile {
    padding: 0 $container-padding-mobile;
  }
}

#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  @include mobileUp() {
    height: 100vh;
  }
}

.Toastify {
  flex-shrink: 1;
}

.main {
  @extend %container;
  flex-grow: 2;
  background-color: $color-black;
  display: flex;
  flex-direction: column;
  padding: 0;

  @include mobileUp() {
    overflow: auto;
  }
  
  .site-message--wrapper & {
    flex-grow: 0;
  }
}

.site-message {
  @extend %container;
  text-align: center;
  color: $color-white;
  max-width: 700px;
  padding-bottom: 100px;

  h1 {
    margin-bottom: 40px;

    span {
      display: block;
      font-weight: 400;
    }
  }

  p {
    line-height: 1.7;
  }

  &--wrapper {
    justify-content: center;

    &::after {
      @extend %container;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: $color-black;
      z-index: -1;
    }
  }
}

.page__status {
  margin: auto;
  color: $color-white;
  font-size: 1.5em;
}

.btn {
  @extend %reset-Button;
  background-color: $color-white;
  color: $color-black;
  border-radius: 100px;
  padding: 10px 20px;
  border: 1px solid $color-white;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;

  &[disabled] {
    opacity: .4;
    cursor: default;
  }

  @include mobile {
    font-size: 12px;
    padding: 5px 10px;
  }

  &:hover {
    background-color: transparent;
    color: $color-white;
  }

  &.lg {
    font-size: 18px;
    padding: 15px 30px;

    @include mobile {
      font-size: 15px;
      padding: 7px 15px;
    }
  }

  &.sm {
    font-size: 12px;
    padding: 5px 10px;

    @include mobile {
      font-size: 12px;
      padding: 5px 10px;
    }
  }

  &.block {
    display: block;
    width: 100%;
  }

  &.green {
    background-color: $color-green;
    border-color: $color-green;
    color: $color-white;
  }

  &.yellow {
    background-color: $color-yellow;
    border-color: $color-yellow;
    color: $color-white;
  }

  &.red {
    background-color: $color-red;
    border-color: $color-red;
    color: $color-white;
  }

  &.outline {
    background-color: transparent;
    color: $color-white;

    &:hover {
      background-color: $color-white;
      color: $color-black;
    }
  }

  &.link {
    border: 0;
    padding: 0;
    background-color: transparent;
    border-radius: 0;
    color: $color-white;

    &.green {
      color: $color-green;
    }

    &.yellow {
      color: $color-yellow;
    }

    &.red {
      color: $color-red;
    }
  }
}

span.ripple {
  position: absolute;
  border-radius: 50%;
  transform: scale(0);
  animation: ripple 600ms linear;
  background-color: rgba(255, 255, 255, 0.7);
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}


@import "./components/header";
@import "./components/competition/index";
@import "./components/athlete/index";
@import "./components/division/index";
@import "./components/review/index";
@import "./components/wod/index";
@import "./components/video/index";

@import "./pages/login";
@import "./pages/dashboard";
@import "./pages/competition";
@import "./pages/review";
