.header {
  @extend %container;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 25px;
  position: sticky;
  height: 100px;
  background-color: $color-black;
  z-index: 10;

  .site-message--wrapper & {
    position: relative;

    .logout {
      display: none;
    }
  }

  .back__button {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $color-white;

    @include mobile {
      span {
        display: none;
      }
    }
    
    img {
      margin-right: 10px;
    }
  }

  .logo {
    margin: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;

    @include mobile {
      #app:not(.site-message--wrapper) {
        position: static;
        transform: none;
        margin: 0;
        margin-left: 25px;
      }
    }
  }
}


#header_portal {
  margin-left: auto;

  button {
    margin-left: 15px;

    &.link {
      font-weight: 500;
    }
  }

  &:not(:empty) ~ * {
    display: none;
  }
}