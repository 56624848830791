.login {
  @extend %container;
  
  max-width: 300px;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 150px;

  input {
    background: $color-gray;
    width: 100%;
    display: block;
    height: 50px;
    padding: 0 20px;
    border: 0;
    color: $color-white;
    margin: 20px 0;
    outline: none;
    border-radius: 5px;
  }
}