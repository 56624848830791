
.division {
  &__list {
    list-style-type: none;
    display: flex;
    padding: 0;
    overflow-x: auto;
    border-top: 1px solid $color-gray;
    border-bottom: 1px solid $color-gray;
    position: sticky;
    top: 100px;
    background-color: $color-black;
    z-index: 9;
    margin: 0;

    @include mobileUp() {
      top: 0;
    }
  }

  &__item {
    padding: 20px 25px;
    color: $color-white;
    display: block;
    white-space: nowrap;
    cursor: pointer;
    font-weight: 400;
    letter-spacing: .3px;
    
    &.active {
      background-color: $color-yellow;
      pointer-events: none;
    }
  }
}