.dashboard {
  @extend %container;


  display: flex;
  flex-direction: column;
  color: $color-white;
  text-align: center;
  font-size: 48px;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  padding-bottom: 150px;

  .btn {
    margin-top: 50px;
    min-width: 250px;
  }
}