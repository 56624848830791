.wod {
  &__list {
    padding: 0;
    list-style-type: none;
  }

  &__single {
    @extend %reset-Button;
    width: 100%;
    color: $color-white;
    display: flex;
    align-items: center;
    padding: 30px $container-padding;
    cursor: pointer;
    text-align: left;
    text-decoration: none;
    
    .btn {
      margin-left: auto;
    }

    dl {
      dd {
        margin-left: 0;
        font-size: 12px;
        color: lighten($color-gray, 20%);
        font-weight: 500;
      }
    }
    

    &:hover {
      background-color: $color-gray;
    }

    @include mobile {
      padding: 10px $container-padding-mobile;
    }

    &:not(:first-child) {
      border-top: 1px solid $color-gray;
    }
  }
}