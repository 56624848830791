
.video-progress {
  @extend %container;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  pointer-events: all;
  z-index: 4;
  
  &__bar {
    z-index: 3;
    top: 0;
    left: 0;
    right: 0;

    &,
    &::after {
      content: '';
      position: absolute;
      display: block;
    }
    
    &::after {
      height: 2px;
      background-color: $color-green;
      z-index: -1;
      right: 0;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &, &-wrapper {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &-wrapper {
      width: 100%;
      display: block;
      margin: 0;
      position: relative;
    }
  }

  &__taps {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 100%;
    width: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .tap {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: $color-green;
      transform: translateY(-50%);
      z-index: 4;
    }

    &::after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      background-color: $color-green;
    }
    
    &.active::before,
    &:hover::before {
      background-color: $color-yellow;
    }

    &.active::after,
    &:hover::after {
      background-color: $color-yellow;
    }

    
    &.negative::before,
    &.negative::after {
      background-color: lighten($color-red, 15%);
    }

    &.negative.active::after,
    &.negative:hover::after,
    &.negative.active::before,
    &.negative:hover::before {
      background-color: $color-red;
    }
    
    &.review::before {
      background-color: transparent;
      content: '\02193';
      color: $color-green;
      font-size: 30px;
      font-weight: bold;
      top: -9px;
      height: auto;
      width: auto;
      left: 0;
      transform: translateX(-50%);
    }

    &.review.active:before,
    &.review:hover:before {
      color: $color-yellow;
      background-color: transparent;
    }
  }

  &__pointer {
    width: 15px;
    height: 15px;
    background-color: $color-white;
    border: 0;
    border-radius: 50%;
    position: absolute;
    z-index: 0;
    cursor: grab;
    user-select: none;
    touch-action: none;
    top: 50%;
    transform: translate(-50%, -50%);
    outline: 0;
    z-index: 4 !important;
    box-shadow: 0 0 15px 5px rgba($color-black, .5)
  }

  .flag-start,
  .flag-end {
    @extend .tap;
    position: absolute;
    pointer-events: none;

    &::before {
      content: '\02691';
      display: block;
      position: absolute;
      font-size: 20px;
      top: -1px;
      color: $color-white;
      background-color: transparent !important;
    }
  }

  .flag-start::after {
    display: none;
  }

  .flag-end {
    &::after {
      background-color: $color-green !important;
    }
  }

  &__zoom {
    &__control {
      position: absolute;
      right: $container-padding;
      bottom: 100px;
      z-index: 4;

      @include mobile {
        right: $container-padding-mobile;
      }
    }

    &__toggle {
      position: relative;

      &:hover {
        background-color: $color-white;
        color: $color-black;
      }

      &.active {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &::before {
        content: attr(data-char);
        position: absolute;
        top: 6px;
        left: 16px;
        display: block;
        font-size: 10px;
      }
    }

    &__actions {
      position: absolute;
      top: 0;
      transform: translateX(-100%);
      display: flex;
      bottom: 0;
      left: 1px;
      align-items: center;
      border-radius: 100px;
      height: 100%;
      background-color: $color-white;
      padding-right: 10px;
      padding-left: 10px;
      border-top-right-radius:0 ;
      border-bottom-right-radius:0 ;

      output {
        width: 50px;
        text-align: center;
      }
    }
  }
}