.competition {
  &__item {
    @extend %reset-Button;
    width: 100%;
    color: $color-white;
    display: flex;
    align-items: center;
    padding: 30px $container-padding;
    cursor: pointer;
    text-align: left;
    

    &:hover {
      background-color: $color-gray;
    }

    @include mobile {
      padding: 10px $container-padding-mobile;
    }

    &:not(:first-child) {
      border-top: 1px solid $color-gray;
    }
  }
  
  &__ordinal {
    margin-right: 10px;
  }

  &__review-button {
    margin-left: auto;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;

    &-wrapper {
      flex: 1;
      min-width: 0; /* or some value */
      margin-right: 5px;
    }
  }
}