.review {
  &__video {
    video {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      top: 0;
      left: 0;
      background-color: #000;
      z-index: 1;
      outline: 0;
    }

    &-status {
      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0;
        background: transparent;
        box-sizing: border-box;
        width: 0;
        height: 60px;
        border-color: transparent transparent transparent rgba($color-white, .7);
        transition: 100ms all ease;
        cursor: pointer;
        z-index: 3;

        // play state
        border-style: solid;
        border-width: 30px 0 30px 60px;
      }


      &.playing::after {
        border-style: double;
        border-width: 0px 0 0px 60px;
        transition: 200ms ease;
        opacity: 0;
      }

      &.playing.is-hovering::after {
        opacity: 1;
      }

      &:hover::after {
        border-color: transparent transparent transparent $color-white;
      }
    }
        

    

    &-wrapper {
      flex-grow: 1;
      position: relative;

      // SHADOW
      &::after {
        content: '';
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        pointer-events: none;
        background: linear-gradient(to bottom, rgba(#000, .9), transparent 40%);
      }
    }

    &-overlay {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 3;
      pointer-events: none;

      &__top {
        @extend %container;
        padding-top: 15px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;

        
        @include mobile {
          align-items: flex-start;
        }
      }

      &__bottom {
        @extend %container;
        padding-bottom: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }

  &__controls {
    flex-basis: 100px;
    display: flex;
    overflow: auto;
    direction: rtl;

    button {
      @extend %reset-Button;
      flex-grow: 1;
      flex-basis: 0;
      color: $color-white;
      font-weight: 500;
      font-size: 14px;
      background-color: $color-black;
      position: relative;
      overflow: hidden;
      min-width: 10%;
      direction: ltr;

      @include mobile {
        min-width: 20%;
      }

      &[disabled] {
        opacity: .3;
      }

      &:hover {
        background-color: lighten($color-black, 2%);
      }
      
      &.selected,  &.selected:hover {
        background-color: $color-green;
      }

      &:not(:first-child) {
        border-right: 1px solid $color-gray;
      }
    }
  }

  &__details {
    color: $color-white;
    display: flex;
    margin: 0;
    margin-left: auto;

    @include mobile {
      display: block;
      text-align: right;
    }

    dd {
      margin-left: 10px;
      font-size: 17px;
      font-weight: bold;
    }
    dt {
      margin-left: 30px;

      @include mobile {
        &:not(:first-child) {
          margin-top: 15px;
        }
      }
    }
  }

  &__activity {
    @extend %container;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(#000, .4);
    display: flex;
    flex-direction: column-reverse;
    padding-bottom: 80px;
    align-items: flex-end;
    overflow-y: auto;
    direction: rtl;
    pointer-events: all;

    &__message-container {
      max-width: 500px;
    }

    &__actions {
      display: none;
      align-items: center;
    }

    &__log {
      margin-left: 40px;
      color: $color-white;
      font-size: 13px;
      padding: 8px 25px;
      border-radius: 15px;
      background-color: $color-green;
      margin-top: 10px;
      direction: ltr;
      display: flex;
      align-items: flex-start;
      max-width: 100%;
      min-height: 35px;

      &:hover {
        padding-right: 15px;

        .review__activity__actions {
          display: flex;
        }
      }

      p {
        margin: 0;
        display: inline;

        &::before {
          content: '\00a -\00a0\00a'
        }
      }

      &.negative {
        background-color: $color-red;
      }

      .seek,
      .log__remover {
        @extend %reset-Button;
      }

      .seek {
        margin-right: 5px;

        &:hover {
          text-decoration: underline;
        }
      }

      .edit__message {
        margin-left: 10px;
        font-weight: 500;
      }

      .log__remover {
        margin-left: 10px;
        position: relative;
        align-items: center;
        display: inline-flex;

        &::before {
          content: '|';
          margin-right: 10px;
        }
        img {
          width: 7px;
          height: 7px;
        }
      }
    }

    &__toggler {
      @extend %reset-Button;
      width: 25px;
      height: 25px;
      position: relative;
      z-index: 4;
      pointer-events: all;
      
      img {
        width: 100%;

        & + img {
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          border: 1px solid $color-white;
          border-radius: 50%;
          padding: 5px;
        }
      }

      &.active {
        img {
          opacity: 0;

          & + img {
            opacity: 1;
          }
        }
      }
    }
  }

  &__submit-controls {
    display: flex;
    top: 0;
  }
}